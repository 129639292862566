/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import StarFilledIcon from '@/components/icons/star-icon'
import getDashboardMain from '@/features/analytics/api'
import { useQuery } from '@tanstack/react-query'
import { Flex, Progress, Skeleton, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'

function RatingHeader(): JSX.Element {
  const [isDarkMode, setIsDarkMode] = useState(false)
  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [])

  const { data, isLoading } = useQuery({
    queryKey: ['dashboardMain'],
    queryFn: getDashboardMain,
  })

  const overAllRating = useMemo(() => {
    // const a =
    //   data?.reviews?.reduce(
    //     (sum: number, obj: any) => sum + obj?.review_count,
    //     0,
    //   ) / data?.reviews?.length

    // if (isNaN(a)) {
    //   return 0
    // }
    // return parseFloat(a.toFixed(1))

    let totalWeightedRating = 0
    let totalReviews = 0

    data?.reviews.forEach((item: any) => {
      totalWeightedRating += item.rating * item.review_count
      totalReviews += item.review_count
    })

    return parseFloat(totalWeightedRating / totalReviews).toFixed(1)
  }, [data])

  const allRatesCount = useMemo(
    () =>
      data?.reviews?.reduce(
        (sum: number, obj: any) => sum + obj?.review_count,
        0,
      ),
    [data],
  )

  function addMissingRatesAndSort(arr: any): any {
    const rateMap = new Map(arr?.map((item: any) => [item.rate, item.count]))
    const result = []
    // eslint-disable-next-line no-plusplus
    for (let rate = 1; rate <= 5; rate++) {
      if (rateMap.has(rate)) {
        result.push({ rate, count: rateMap.get(rate) })
      } else {
        result.push({ rate, count: 0 })
      }
    }
    result.sort((a, b) => b.rate - a.rate)
    return result
  }

  const statistics = useMemo(() => {
    const dataStatistics = data?.reviews?.map((item: any) => ({
      rate: item?.rating,
      count: item?.review_count,
    }))

    if (!dataStatistics) {
      return []
    }

    return addMissingRatesAndSort(dataStatistics)
  }, [data])

  return isLoading ? (
    <Skeleton active />
  ) : (
    <Flex className="w-full" gap={54}>
      <Flex vertical className="items-start">
        <Flex vertical className="justify-center">
          <StarFilledIcon
            width="52"
            height="52"
            style={{
              color: '#FA8F54',
            }}
          />

          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-[32px] !m-0 !mt-[8px] !mb-[20px] dark:!text-[#FFF] text-center">
            {overAllRating}
          </Typography.Title>
        </Flex>

        <Flex gap={8} className="items-center">
          <Typography.Text className="text-nowrap !text-[#878787] !font-normal !text-base !m-0">
            -из {allRatesCount} отзывов
          </Typography.Text>

          {/* <Typography.Text className="text-nowrap !text-[#1E1E1E] bg-[#F1F2F4] rounded-[13px] !font-normal !text-[12px] !m-0 p-[6px] dark:bg-[#272B30] dark:!text-[#FFF]">
              +1 на этой неделе
            </Typography.Text> */}
        </Flex>
      </Flex>

      <Flex vertical gap={12} className="w-full">
        {statistics?.map((item: any) => (
          <Flex key={item.rate} gap={16} className="items-center w-full">
            <Flex gap={2}>
              <StarFilledIcon
                width="20"
                height="20"
                style={{
                  color:
                    item.rate > 0
                      ? '#FA8F54'
                      : isDarkMode
                      ? '#272B30'
                      : '#E5E7EB',
                }}
              />
              <StarFilledIcon
                width="20"
                height="20"
                style={{
                  color:
                    item.rate > 1
                      ? '#FA8F54'
                      : isDarkMode
                      ? '#272B30'
                      : '#E5E7EB',
                }}
              />
              <StarFilledIcon
                width="20"
                height="20"
                style={{
                  color:
                    item.rate > 2
                      ? '#FA8F54'
                      : isDarkMode
                      ? '#272B30'
                      : '#E5E7EB',
                }}
              />
              <StarFilledIcon
                width="20"
                height="20"
                style={{
                  color:
                    item.rate > 3
                      ? '#FA8F54'
                      : isDarkMode
                      ? '#272B30'
                      : '#E5E7EB',
                }}
              />
              <StarFilledIcon
                width="20"
                height="20"
                style={{
                  color:
                    item.rate > 4
                      ? '#FA8F54'
                      : isDarkMode
                      ? '#272B30'
                      : '#E5E7EB',
                }}
              />
            </Flex>

            <Flex className="w-full">
              <Progress
                percent={(item.count / 6) * 100}
                status="active"
                trailColor={isDarkMode ? '#272B30' : '#E5E7EB'}
                strokeColor="#FA8F54"
                showInfo={false}
              />
            </Flex>

            <Flex className="items-center">
              <Typography.Text className="!text-[#878787] !text-sm !font-normal">
                {item.count}
              </Typography.Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default RatingHeader
