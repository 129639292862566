/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-floating-promises */
import HFPassword from '@/components/FormElements/HFPassword'
import FRow from '@/components/FormElements/HFRow'
import BuildingIcon from '@/components/icons/building'
import CallIcon from '@/components/icons/call-icon'
import FacebookIcon2 from '@/components/icons/facebook-icon'
import HeadphonesIcon from '@/components/icons/headphone'
import InstagramColoredIcon from '@/components/icons/instagram-icon'
import LockKeyIcon from '@/components/icons/lock-key'
import LogoDark from '@/components/icons/logo-dark'
import LogoLight from '@/components/icons/logo-light'
import MailIcon from '@/components/icons/mail-icon'
import PowerIcon from '@/components/icons/power-icon'
import TelegramColoredIcon from '@/components/icons/telegram-color-icon'
import TwitterIcon from '@/components/icons/twitter-icon'
import { useAuthContext } from '@/contexts'
import { updatePassword } from '@/features/auth/api'
import {
  CloseCircleOutlined,
  CloseOutlined,
  GlobalOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  Button,
  Divider,
  Flex,
  Image,
  Modal,
  Popover,
  Switch,
  Typography,
} from 'antd'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Avatar from '../assets/default-avatar.svg'
import SignOutIcon from '../assets/sign-out.svg'
import getInformationSupport from '../api'
import useMessage from '@/hooks/useShowMessage'

function ProfilePopover({
  isDarkMode,
  toggleDarkMode,
  hide,
  handleOpenChange,
  open,
}: {
  isDarkMode: boolean
  toggleDarkMode: () => void
  hide: () => void
  handleOpenChange: (newOpen: boolean) => void
  open: boolean
}): JSX.Element {
  const { setIsAuth } = useAuthContext()
  const navigate = useNavigate()
  const [openModalUpdatePassword, setOpenModalUpdatePassword] = useState(false)
  const [openModalSupport, setOpenModalSupport] = useState(false)
  const { control, getValues, watch } = useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showMessage = useMessage()

  const { mutate: mutateUpdatePassword, isLoading: isLoadingUpdate } =
    useMutation({
      mutationFn: updatePassword,
      onSuccess: () => {
        setIsAuth(true)
        navigate('/')
        setOpenModalUpdatePassword(false)
      },
      onError: (error: Error) => {
        console.error(error)
      },
    })

  const { data } = useQuery({
    queryKey: ['informationSupport'],
    queryFn: () => getInformationSupport(),
  })

  const informationSupport = useMemo(() => {
    return {
      phone: data?.results?.filter((item: any) => item?.key === 'phone'),
      email: data?.results?.filter((item: any) => item?.key === 'email'),
      socialNetworks: data?.results?.filter(
        (item: any) =>
          item?.key === 'telegram' ||
          item?.key === 'instagram' ||
          item?.key === 'facebook' ||
          item?.key === 'twitter',
      ),
    }
  }, [data])

  return (
    <>
      <Popover
        overlayInnerStyle={{
          backgroundColor: isDarkMode ? '#272B30' : '#FFF',
        }}
        content={
          <Flex vertical className="min-w-[290px]">
            <Button
              type="link"
              onClick={() => {
                hide()
                navigate('/residential-complex/residential-complex-page')
              }}
              className="p-[16px] text-start h-max text-[#1E1E1E] hover:!text-[#1E1E1E] hover:!bg-[#F1F2F4] flex items-center justify-start dark:text-[#FFF] dark:hover:!bg-[#111315] dark:hover:!text-[#FFF]"
            >
              <BuildingIcon />
              Информация о ЖК
            </Button>
            <Button
              type="link"
              onClick={() => {
                setOpenModalUpdatePassword(true)
                hide()
              }}
              className="p-[16px] text-start h-max text-[#1E1E1E] hover:!text-[#1E1E1E] hover:!bg-[#F1F2F4] flex items-center justify-start dark:text-[#FFF] dark:hover:!bg-[#111315] dark:hover:!text-[#FFF]"
            >
              <LockKeyIcon
                style={{
                  color: isDarkMode ? '#FFF' : '#1E1E1E',
                }}
              />
              Изменить пароль
            </Button>
            <Button
              type="link"
              onClick={() => {
                setOpenModalSupport(true)
                hide()
              }}
              className="p-[16px] text-start h-max text-[#1E1E1E] hover:!text-[#1E1E1E] hover:!bg-[#F1F2F4] flex items-center justify-start dark:text-[#FFF] dark:hover:!bg-[#111315] dark:hover:!text-[#FFF]"
            >
              <HeadphonesIcon
                style={{
                  color: isDarkMode ? '#FFF' : '#1E1E1E',
                }}
              />
              Связаться с поддержкой
            </Button>
            <Divider className="m-0" />
            <Button
              type="link"
              className="p-[16px] text-start h-max text-[#1E1E1E] hover:!text-[#1E1E1E] flex items-center justify-between dark:text-[#FFF] dark:hover:!bg-[#111315] dark:hover:!text-[#FFF]"
            >
              Темный режим
              <Switch
                onChange={() => {
                  toggleDarkMode()
                }}
                checked={isDarkMode}
              />
            </Button>
            <Divider className="m-0" />
            <Button
              type="link"
              onClick={() => {
                setIsModalOpen(true)
                hide()
              }}
              className="p-[16px] text-start h-max text-[#1E1E1E] hover:!text-[#1E1E1E] hover:!bg-[#F1F2F4] flex items-center justify-start dark:text-[#FFF] dark:hover:!bg-[#111315] dark:hover:!text-[#FFF]"
            >
              <PowerIcon
                style={{
                  color: isDarkMode ? '#FFF' : '#1E1E1E',
                }}
              />
              Выйти с аккаунта
            </Button>
          </Flex>
        }
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button
          type="link"
          className="p-0 overflow-hidden rounded-full h-[46px] w-[46px] flex items-center justify-center ml-[16px]"
        >
          <Image src={Avatar} preview={false} />
        </Button>
      </Popover>

      <Modal
        width={500}
        footer={null}
        centered
        open={openModalUpdatePassword}
        onOk={() => {
          setOpenModalUpdatePassword(false)
        }}
        onCancel={() => {
          setOpenModalUpdatePassword(false)
        }}
        className="custom-modal-rc"
      >
        <Flex
          vertical
          gap={24}
          className="border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px] dark:bg-[#1A1D1F] dark:border-[#272B30]"
        >
          <Flex className="w-full items-center justify-center">
            {isDarkMode ? <LogoDark /> : <LogoLight />}
          </Flex>

          <Flex vertical className="items-center">
            <Typography.Title className="!text-2xl !font-medium m-0 mb-[10px] text-[#1E1E1E] dark:!text-[#FFF]">
              Обновить пароль
            </Typography.Title>
            <Typography.Paragraph className="text-center text-base font-normal text-[#878787] dark:!text-[#FFF]">
              Придумайте надежный пароль, состоящий из букв, цифр и других
              символов
            </Typography.Paragraph>
          </Flex>

          <Flex vertical gap={24}>
            <FRow label="Ваш пароль">
              <HFPassword
                control={control}
                name="password1"
                placeholder="Введите ваш пароль"
              />
            </FRow>

            <FRow label="Подтвердить пароль">
              <HFPassword
                control={control}
                name="password2"
                placeholder="Подтвердите ваш пароль"
              />
            </FRow>
          </Flex>

          <Flex>
            <Button
              loading={isLoadingUpdate}
              disabled={watch('password1') !== watch('password2')}
              onClick={() => {
                if (watch('password1') === watch('password2')) {
                  mutateUpdatePassword({
                    password1: getValues('password1'),
                    password2: getValues('password2'),
                  })
                } else {
                  showMessage('error', 'Пароли не совпадают')
                }
              }}
              className="rounded-[8px] bg-[#635BFF] text-[#FFFFFF] font-medium text-base w-full h-auto py-[10px]"
            >
              Обновить пароль
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        closeIcon={null}
        footer={null}
        className="search-modal"
      >
        <Flex
          vertical
          gap={24}
          className="rounded-[12px] overflow-hidden border-[#EEEEEE] border-[1.5px] border-solid p-[24px]"
        >
          <Flex vertical className="items-center relative">
            <Image src={SignOutIcon} preview={false} />

            <Typography.Title className="!text-2xl !font-medium m-0 ml-[16px] text-[#1E1E1E] text-center">
              Вы уверены, что хотите выйти из своей аккаунта?
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setIsModalOpen(false)
              }}
              className="absolute right-1 top-1 w-[24px] h-[24px] p-0"
            >
              <CloseCircleOutlined
                style={{
                  color: '#1E1E1E',
                  fontSize: 24,
                }}
              />
            </Button>
          </Flex>

          <Flex className="w-full items-center justify-center" gap={24}>
            <Button
              onClick={() => {
                setIsModalOpen(false)
              }}
              className="w-[130px] rounded-[14px] border-[1.5px] border-solid border-[#E5E7EB] p-[16px_22px] h-auto text-[#A9A9A9] font-medium text-base"
            >
              Отменить
            </Button>

            <Button
              onClick={() => {
                setIsAuth(false)
              }}
              className="w-[130px] bg-[#635BFF] rounded-[14px] border-[1.5px] border-solid border-[#E5E7EB] p-[16px_22px] h-auto text-[#FFFFFF] font-medium text-base"
            >
              Выйти
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        open={openModalSupport}
        onOk={() => {
          setOpenModalSupport(false)
        }}
        onCancel={() => {
          setOpenModalSupport(false)
        }}
        footer={null}
        centered
        closeIcon={null}
        className="custom-modal-rc"
        width="500px"
      >
        <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px] dark:bg-[#1A1D1F] dark:border-[#272B30]">
          <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0 dark:!text-[#FFF]">
            Связаться с поддержкой
          </Typography.Title>

          <Button
            type="link"
            onClick={() => {
              setOpenModalSupport(false)
            }}
          >
            <CloseOutlined className="text-[#1E1E1E] dark:text-[#FFF]" />
          </Button>
        </Flex>

        <Flex vertical className="p-[24px]" gap={32}>
          <Flex vertical gap={16}>
            <Flex gap={8} className="items-center">
              <CallIcon
                style={{
                  color: '#878787',
                }}
              />
              <Typography.Text className="text-[#878787] font-medium text-base">
                Телефон:
              </Typography.Text>
            </Flex>

            <Flex vertical gap={8}>
              {informationSupport?.phone?.map((item: any) => (
                <Typography.Text
                  key={item.id}
                  className="text-[#373737] font-medium text-xl"
                >
                  {item?.title}
                </Typography.Text>
              ))}
            </Flex>
          </Flex>

          <Flex vertical gap={16}>
            <Flex gap={8} className="items-center">
              <MailIcon
                style={{
                  color: '#878787',
                }}
              />
              <Typography.Text className="text-[#878787] font-medium text-base">
                Электронная почта:
              </Typography.Text>
            </Flex>

            <Flex vertical gap={8}>
              {informationSupport?.email?.map((item: any) => (
                <Typography.Text
                  key={item.id}
                  className="text-[#373737] font-medium text-xl"
                >
                  {item.title}
                </Typography.Text>
              ))}
            </Flex>
          </Flex>

          <Flex vertical gap={16}>
            <Flex gap={8} className="items-center">
              <GlobalOutlined
                style={{
                  color: '#878787',
                }}
              />
              <Typography.Text className="text-[#878787] font-medium text-base">
                Социальные сети:
              </Typography.Text>
            </Flex>

            <Flex gap={16}>
              {informationSupport?.socialNetworks?.map((item: any) => (
                <Flex
                  key={item.id}
                  onClick={() => {
                    window.open(item.title, '_blank')
                  }}
                  className="rounded-full border-[1.5px] border-[#EEEEEE] border-solid p-[12px] cursor-pointer"
                >
                  {item.key === 'telegram' && <TelegramColoredIcon />}
                  {item.key === 'instagram' && <InstagramColoredIcon />}
                  {item.key === 'facebook' && <FacebookIcon2 />}
                  {item.key === 'twitter' && <TwitterIcon />}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}

export default ProfilePopover
